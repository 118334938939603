import { isEmpty } from 'lodash'
import merge from 'ts-deepmerge'
import { PARTICIPANT_STUDIES_CHANGE_SORT } from '@/analytics/events'
import {
  trackStudyDashboardEvent,
  trackStudyDashboardLoad,
  trackStudyDashboardSort,
} from '@/analytics/participant'
import { http, endpoints } from '@/api'
import {
  PROCESSING,
  is as isStatus,
  isActive,
  isReserved,
} from '@/api/statuses'
import { CARD_TYPES, SURVEY_BUILDER_APP_KEY } from '@/constants'
import pendo from '@/integrations/pendo'
import rollbar from '@/integrations/rollbar'
import rum from '@/integrations/rum'
import router from '@/router'
import { sortByDate, sortByNumber } from '@/utils/sort'
import { localStorageSetItem } from '@/utils/storage'
import {
  getPreferredSort,
  getPreferredRewardFilter,
  sortTrackMapping,
} from '@/utils/studies'
import { isStudyMultiSubmission } from '@/utils/submissions-config'
import common, { types as commonTypes } from '../../common'

export const types = {
  ADD_PUSHER_CHANNEL: 'ADD_PUSHER_CHANNEL',
  RESET_PUSHER_CHANNELS: 'RESET_PUSHER_CHANNELS',
  SET_SELECTED_STUDY: 'SET_SELECTED_STUDY',
  SET_SHOW_VERIFICATION_CARD_DETAILS: 'SET_SHOW_VERIFICATION_CARD_DETAILS',
  SET_SHOW_WELCOME_PROMPT: 'SET_SHOW_WELCOME_PROMPT',
  SET_QUESTIONS_CATEGORY: 'SET_QUESTIONS_CATEGORY',
  SET_ACTIVE_SORT: 'SET_ACTIVE_SORT',
  SET_ACTIVE_REWARD_FILTER: 'SET_ACTIVE_REWARD_FILTER',
}

const state = {
  // TODO: it might be cleaner to split this store into state for /studies and /studies/:id at some point
  // state for /studies view
  studiesList: [],
  studies: {},
  // active sort
  activeSort: getPreferredSort(),
  activeRewardFilter: getPreferredRewardFilter(),
  selectedStudy: {},
  questionsCategory: {},
  showVerificationCardDetails: false,
  showWelcomePrompt: false,

  // state for /studies/:id view
  study: {},
  // TODO: this looks unused now. Cleanup in separate PR
  pusherChannels: [],
}

const mutations = {
  [types.ADD_PUSHER_CHANNEL](state, channel) {
    state.pusherChannels.push(channel)
  },

  [types.RESET_PUSHER_CHANNELS](state) {
    state.pusherChannels = []
  },

  [types.SET_SELECTED_STUDY](state, study) {
    state.selectedStudy = study
  },

  [types.SET_SHOW_VERIFICATION_CARD_DETAILS](state, status) {
    state.showVerificationCardDetails = status
  },

  [types.SET_SHOW_WELCOME_PROMPT](state, status) {
    state.showWelcomePrompt = status
  },

  [types.SET_QUESTIONS_CATEGORY](state, questionsCategory) {
    state.questionsCategory = questionsCategory
  },

  [types.SET_ACTIVE_SORT](state, newSort) {
    state.activeSort = newSort
  },

  [types.SET_ACTIVE_REWARD_FILTER](state, newFilter) {
    state.activeRewardFilter = newFilter
  },
}

const actions = {
  clearSelectedCard({ commit }) {
    commit(types.SET_SELECTED_STUDY, {})
    commit(types.SET_QUESTIONS_CATEGORY, {})
    commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
    commit(types.SET_SHOW_WELCOME_PROMPT, false)
  },

  fetchList(
    { dispatch, commit, state, getters, rootGetters },
    { automatic } = {}
  ) {
    return http
      .get(endpoints.STUDIES_PARTICIPANT, { useGlobal429ErrorHandling: false })
      .then(data => {
        commit(commonTypes.SET_LIST, data.results)

        const displayStudies = getters?.displayStudiesList
        const activeOngoingStudies = getters?.activeOngoingStudies
        const visibleStudies = getters?.visibleStudies
        const studiesExist =
          displayStudies?.length > 0 || activeOngoingStudies?.length > 0
        const isDesktop = rootGetters['global/isMediumWidthAndUp']

        const {
          selectedStudy,
          questionsCategory,
          studies,
          showVerificationCardDetails,
          showWelcomePrompt,
        } = state

        trackStudyDashboardLoad(visibleStudies?.length || 0, automatic ?? false)

        // Deselect active study if it has fallen off the list
        if (selectedStudy?.id && !studies[selectedStudy?.id]) {
          // If on desktop, select the first study if there are studies
          if (isDesktop && studiesExist) {
            dispatch('setSingle', {
              type: CARD_TYPES.STUDY,
              item: activeOngoingStudies?.length
                ? activeOngoingStudies[0]
                : displayStudies[0],
              automatic: true,
            })
          } else {
            return dispatch('clearSelectedCard')
          }
        }

        // When the studies list is updated, select the first study, only on desktop, if there is no card selected
        if (
          !selectedStudy?.id &&
          !questionsCategory?.id &&
          !showVerificationCardDetails &&
          !showWelcomePrompt
        ) {
          if (isDesktop && studiesExist) {
            return dispatch('setSingle', {
              type: CARD_TYPES.STUDY,
              item: activeOngoingStudies?.length
                ? activeOngoingStudies[0]
                : displayStudies[0],
              automatic: true,
            })
          } else {
            return dispatch('clearSelectedCard')
          }
        }

        // if the selected study is a ongoing study, use the list item data instead
        // of the full study data (since we need study.submission_started_at)
        const study = activeOngoingStudies?.find(
          ({ id }) => id === selectedStudy.id
        )
        if (study) {
          dispatch('setSingle', {
            type: CARD_TYPES.STUDY,
            item: study,
            automatic: true,
          })
        }

        if (typeof studies === 'object') {
          rum.setGlobalContextProperty(
            'studies_count',
            Object.keys(studies).length
          )
        }
      })
      .catch(async error => {
        rollbar.error(error)

        if (error?.response?.status === 429) {
          const studiesPath = router.resolve({ name: 'studies' }).href
          await router.push({
            name: 'error.participant-studies-rate-limited',
          })

          // keep url as '/studies' rather than '/429' so that
          // the user can refresh the page and try again
          history.replaceState(null, '', studiesPath)
        }
      })
  },

  setActiveSort({ commit, dispatch, getters, rootGetters }, newSort) {
    commit(types.SET_ACTIVE_SORT, newSort)
    localStorageSetItem('preferredSort', newSort)

    const displayStudies = getters.displayStudiesList
    const isDesktop = rootGetters['global/isMediumWidthAndUp']

    // Select the 1st study after sorting change, if the user is on desktop
    if (isDesktop) {
      dispatch('setSingle', {
        type: CARD_TYPES.STUDY,
        item: displayStudies[0],
        automatic: true,
      })
    }

    pendo.track(PARTICIPANT_STUDIES_CHANGE_SORT, {
      sort: newSort,
    })
  },

  setActiveRewardFilter({ commit, dispatch, getters, rootGetters }, newFilter) {
    commit(types.SET_ACTIVE_REWARD_FILTER, newFilter)
    localStorageSetItem('preferredFilter', newFilter)

    const displayStudies = getters.displayStudiesList
    const isDesktop = rootGetters['global/isMediumWidthAndUp']

    // Select the 1st study after filter change, if the user is on desktop
    if (isDesktop) {
      dispatch('setSingle', {
        type: CARD_TYPES.STUDY,
        item: displayStudies[0],
        automatic: true,
      })
    }
  },

  fetchSingle({ commit, dispatch }, id) {
    return http.get(endpoints.STUDY_PARTICIPANT(id)).then(async study => {
      dispatch('participant/surveys/reset', undefined, { root: true })

      if (study.external_app === SURVEY_BUILDER_APP_KEY) {
        await dispatch('participant/surveys/fetch', study.external_id, {
          root: true,
        })
      }

      commit(commonTypes.SET_SINGLE, study)

      let { submission } = study
      const { submissions } = study

      if (Array.isArray(submissions)) {
        if (isStudyMultiSubmission(study)) {
          submission =
            submissions.find(
              sub => isActive(sub.status) || isReserved(sub.status)
            ) ?? {}
        } else {
          submission = submissions[0] ?? {}
        }
      } else if (isEmpty(submission)) {
        submission = {}
      }

      if (!isEmpty(submission)) {
        dispatch('participant/submissions/subscribe', submission, {
          root: true,
        })
      }

      commit('participant/submissions/SET_SINGLE', submission, { root: true })
      return study
    })
  },

  setSingle({ commit, getters, rootState }, { type, item, automatic }) {
    if (type === CARD_TYPES.STUDY) {
      commit(types.SET_SELECTED_STUDY, item)

      const { currency_code } = rootState.auth.user
      const visibleStudies = getters.visibleStudies
      trackStudyDashboardEvent('viewed-study-details', item.id, {
        studies: visibleStudies,
        currencyCode: currency_code,
        automatic,
      })

      // Clear the selected questions category and set show verification card details to false
      commit(types.SET_QUESTIONS_CATEGORY, {})
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
      commit(types.SET_SHOW_WELCOME_PROMPT, false)
    }

    if (type === CARD_TYPES.QUESTIONS) {
      commit(types.SET_QUESTIONS_CATEGORY, item)

      // Clear the selected study and set show verification card details to false
      commit(types.SET_SELECTED_STUDY, {})
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
      commit(types.SET_SHOW_WELCOME_PROMPT, false)
    }

    if (type === CARD_TYPES.VERIFICATION) {
      commit(types.SET_SHOW_WELCOME_PROMPT, false)
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, true)

      // Clear the selected study and questions category
      commit(types.SET_QUESTIONS_CATEGORY, {})
      commit(types.SET_SELECTED_STUDY, {})
    }

    if (type === CARD_TYPES.WELCOME) {
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
      commit(types.SET_SHOW_WELCOME_PROMPT, true)

      // Clear the selected study and questions category
      commit(types.SET_QUESTIONS_CATEGORY, {})
      commit(types.SET_SELECTED_STUDY, {})
    }
  },

  dismiss({ dispatch, commit, getters, rootGetters, rootState }, study) {
    const { id } = study
    const isDesktop = rootGetters['global/isMediumWidthAndUp']

    return http.post(endpoints.DISMISS_STUDY(id)).then(() => {
      // Remove the study from the list
      commit(commonTypes.DELETE, id)

      const displayStudies = getters.displayStudiesList
      const visibleStudies = getters.visibleStudies
      const { currency_code } = rootState.auth.user

      trackStudyDashboardEvent('dismissed-study', id, {
        studies: visibleStudies,
        currencyCode: currency_code,
      })

      // Select the 1st study after dismissing, if there are studies and the user is on desktop
      if (displayStudies.length > 0 && isDesktop) {
        dispatch('setSingle', {
          type: CARD_TYPES.STUDY,
          item: displayStudies[0],
          automatic: true,
        })
      } else {
        dispatch('clearSelectedCard')
      }
    })
  },

  onActiveOngoingStudyTimeout(
    { dispatch, commit, rootGetters, getters },
    studyId
  ) {
    const study = getters.activeOngoingStudies.find(({ id }) => id === studyId)
    if (!study) {
      return
    }
    dispatch('clearSelectedCard')
    dispatch('participant/submissions/clearActiveOngoingStudy', studyId, {
      root: true,
    })
    commit(commonTypes.DELETE, studyId)
    const displayStudies = getters.displayStudiesList
    const isDesktop = rootGetters['global/isMediumWidthAndUp']
    if (displayStudies.length > 0 && isDesktop) {
      dispatch('setSingle', {
        type: CARD_TYPES.STUDY,
        item: displayStudies[0],
        automatic: true,
      })
    }
  },
}

const getters = {
  byStatus: (state, getters) => statuses => {
    if (!getters.all) {
      return []
    }
    if (!Array.isArray(statuses)) {
      statuses = [statuses]
    }
    // always include PROCESSING status
    statuses.push(PROCESSING)
    return getters.all.filter(study => isStatus(study.status, statuses))
  },

  denormalized: state => {
    const { study, researcher } = state
    if (!study || !researcher) {
      return {}
    }
    return {
      ...study,
      researcher: researcher[study.researcher],
    }
  },

  isCardSelected:
    state =>
    ({ type, item }) => {
      switch (type) {
        case CARD_TYPES.STUDY:
          return (
            !isEmpty(state.selectedStudy) && item.id === state.selectedStudy.id
          )
        case CARD_TYPES.QUESTIONS:
          return (
            !isEmpty(state.questionsCategory) &&
            item.id === state.questionsCategory.id
          )
        default:
          return false
      }
    },

  isStudySelected: state => !isEmpty(state.selectedStudy),
  selectedStudy: state => state.selectedStudy,
  isQuestionCardSelected: state => !isEmpty(state.questionsCategory),
  questionsCategorySelected: state => state.questionsCategory,
  isVerificationCardSelected: state => state.showVerificationCardDetails,
  isWelcomePromptSelected: state => state.showWelcomePrompt,
  activeSort: ({ activeSort }) => {
    trackStudyDashboardSort(sortTrackMapping[activeSort])
    return activeSort
  },
  activeRewardFilter: ({ activeRewardFilter }) => activeRewardFilter,
  originalStudiesCount: (_state, getters) =>
    getters.allStudiesExceptActiveOngoing.length,

  activeOngoingStudies: (state, _getters, _rootState, rootGetters) => {
    const user = rootGetters['auth/user']

    const activeOngoingStudyIds = user.active_ongoing_study_ids ?? []

    return activeOngoingStudyIds
      .map(id => state.studies[id])
      .filter(study => !!study)
  },

  allStudiesExceptActiveOngoing: (_state, getters, _rootState, rootGetters) => {
    const allStudies = getters.all
    const user = rootGetters['auth/user']
    const activeOngoingStudyIds = user.active_ongoing_study_ids

    return allStudies.filter(
      study =>
        !(study.is_ongoing_study && activeOngoingStudyIds.includes(study.id))
    )
  },

  // studies view after filtering and sorting
  displayStudiesList: (state, getters, rootState, rootGetters) => {
    const studies = getters.allStudiesExceptActiveOngoing
    if (!studies) {
      return []
    }

    const devicePreferences = rootGetters['auth/devicePreferences']
    const contentTypePreferences = rootGetters['auth/contentTypePreferences']
    const studyTypePreferences = rootGetters['auth/studyTypePreferences']

    if (
      !devicePreferences ||
      !contentTypePreferences ||
      !studyTypePreferences
    ) {
      return []
    }

    const { device_type_preferences, device_requirement_preferences } =
      devicePreferences

    // Create array from object
    const user_device_type_preferences = Object.keys(
      device_type_preferences
    ).filter(key => device_type_preferences[key])

    const user_device_requirement_preferences = Object.keys(
      device_requirement_preferences
    ).filter(key => device_requirement_preferences[key])

    const user_content_type_preferences = Object.keys(
      contentTypePreferences
    ).filter(key => contentTypePreferences[key])

    const user_study_type_preferences = Object.keys(
      studyTypePreferences
    ).filter(key => studyTypePreferences[key])

    const filteredList = studies.filter(study => {
      const {
        device_compatibility,
        peripheral_requirements,
        content_warnings,
        is_custom_screening,
      } = study

      // Make sure the user has SOME device_compatibility from the study in his profile preferences.
      const userDeviceCompatible = device_compatibility.some(device =>
        user_device_type_preferences.includes(device)
      )

      // Make sure the user has EVERY peripheral_requirements from the study in his profile preferences.
      const userPeripheralCompatible = peripheral_requirements.every(
        peripheral => user_device_requirement_preferences.includes(peripheral)
      )

      // Make sure the user has EVERY content warning from the study enabled in his profile preferences.
      const contentCompatible = content_warnings.every(warning =>
        user_content_type_preferences.includes(warning)
      )

      // Make sure the user has the study type in his profile preferences.
      const studyTypeCompatible =
        !is_custom_screening ||
        user_study_type_preferences.includes('in_study_screening')

      return (
        userDeviceCompatible &&
        userPeripheralCompatible &&
        contentCompatible &&
        studyTypeCompatible
      )
    })

    const sortedList = [...filteredList]
    const [sortValue, sortType, sortDirection] = state.activeSort.split('|')

    sortedList.sort((a, b) => {
      const aValue = a[sortValue]
      const bValue = b[sortValue]

      const sortFunction = sortType === 'date' ? sortByDate : sortByNumber

      return sortFunction(aValue, bValue)
    })

    if (sortDirection === 'descending') {
      sortedList.reverse()
    }

    // Always place highlighted studies at the top of the list
    sortedList.sort((a, b) => {
      const priorityFlag = 'is_official_prolific_study'
      return sortByNumber(b[priorityFlag] ?? 0, a[priorityFlag] ?? 0)
    })

    return sortedList
  },

  visibleStudies(_state, getters) {
    return [...getters.activeOngoingStudies, ...getters.displayStudiesList]
  },
}

export default merge(common({ entities: 'studies', entity: 'study' }), {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
})
