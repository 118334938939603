import axios from 'axios'
import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type { CampaignTask, UnitOfDataSection } from '@/api/types'

export type State = {
  task: CampaignTask | null
  loadingUnitOfData: boolean
  unitOfData: UnitOfDataSection[]
  submittingTaskResponse: boolean
}

const SET_TASK = 'SET_TASK'
const SET_LOADING_UNIT_OF_DATA = 'SET_LOADING_UNIT_OF_DATA'
const SET_UNIT_OF_DATA = 'SET_UNIT_OF_DATA'
const SET_SUBMITTING_TASK_RESPONSE = 'SET_SUBMITTING_TASK_RESPONSE'

export default {
  namespaced: true,

  state: {
    task: null,
    loadingUnitOfData: false,
    unitOfData: [],
    submittingTaskResponse: false,
  } as State,

  mutations: {
    [SET_TASK](state: State, task: CampaignTask) {
      state.task = task
    },

    [SET_LOADING_UNIT_OF_DATA](state: State, loadingUnitOfData: boolean) {
      state.loadingUnitOfData = loadingUnitOfData
    },

    [SET_UNIT_OF_DATA](state: State, unitOfData: UnitOfDataSection[]) {
      state.unitOfData = unitOfData
    },

    [SET_SUBMITTING_TASK_RESPONSE](
      state: State,
      submittingTaskResponse: boolean
    ) {
      state.submittingTaskResponse = submittingTaskResponse
    },
  },

  actions: {
    async fetchTask({ commit, dispatch }, { campaignId, taskId }) {
      const task = await http.get<CampaignTask>(
        endpoints.DCTOOL_CAMPAIGN_TASK(campaignId, taskId)
      )
      commit(SET_TASK, task)
      const unitOfDataUrl = task.data_points[0]?.reference.asset_url
      if (unitOfDataUrl) {
        dispatch('fetchUnitOfData', { url: unitOfDataUrl })
      }
    },

    setLoadingUnitOfData({ commit }, loading: boolean) {
      commit(SET_LOADING_UNIT_OF_DATA, loading)
    },

    async fetchUnitOfData({ commit, dispatch }, { url }) {
      dispatch('setLoadingUnitOfData', true)
      const { data: unitOfData } = await axios.get(url)
      commit(SET_UNIT_OF_DATA, unitOfData)
      dispatch('setLoadingUnitOfData', false)
    },

    setSubmittingTaskResponse({ commit }, submitting: boolean) {
      commit(SET_SUBMITTING_TASK_RESPONSE, submitting)
    },

    async createTaskResponse(
      { dispatch, rootGetters },
      { campaignId, taskId, taskResponse }
    ) {
      dispatch('setSubmittingTaskResponse', true)
      await http.post(endpoints.DCTOOL_CAMPAIGN_TASK(campaignId, taskId), {
        participant_id: rootGetters['auth/user'].id,
        responses: taskResponse,
      })
    },
  },

  getters: {
    task: ({ task }) => task,
    loadingUnitOfData: ({ loadingUnitOfData }) => loadingUnitOfData,
    unitOfData: ({ unitOfData }) => unitOfData,
    submittingTaskResponse: ({ submittingTaskResponse }) =>
      submittingTaskResponse,
  },
} satisfies Module<State, unknown>
