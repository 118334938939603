import Cookies from 'js-cookie'
import { DateTime, Duration } from 'luxon'

type State = { count: number; lastDismissed: string | null }

/**
 * Manages state for a dismissible account protection banner promoting multifactor authentication.
 *
 * This class controls the visibility of the AccountProtectionBanner component based on:
 * 1. The number of times the banner has been dismissed
 * 2. The time elapsed since the last dismissal
 *
 * It uses cookies to persist the banner's state across sessions, ensuring a balanced
 * approach between promoting security and respecting user preferences.
 *
 * @property {string} COOKIE_NAME - Name of the cookie storing banner dismissal state
 * @property {number} MAX_DISMISSALS - Maximum number of times the banner can be dismissed
 * @property {Duration} SHOW_INTERVAL - Minimum time interval between banner displays
 *
 * @example
 * const manager = new StateManager();
 * if (manager.shouldShowBanner()) {
 *   // Render AccountProtectionBanner component
 * }
 *
 * // When user dismisses the banner
 * manager.update();
 */
class StateManager {
  private readonly COOKIE_NAME = 'banner_dismissals'
  private readonly MAX_DISMISSALS = 3
  private readonly SHOW_INTERVAL = Duration.fromObject({ weeks: 1 })

  private state: {
    count: number
    lastDismissed: string | null
  }

  constructor() {
    this.state = this.get()
  }

  private getDefaultState(): State {
    return { count: 0, lastDismissed: null }
  }

  private get(): State {
    const cookieValue = Cookies.get(this.COOKIE_NAME)
    return cookieValue ? JSON.parse(cookieValue) : this.getDefaultState()
  }

  private save(): void {
    Cookies.set(this.COOKIE_NAME, JSON.stringify(this.state), { expires: 365 })
  }

  public update(): void {
    this.state.count += 1
    this.state.lastDismissed = DateTime.now().toISO()
    this.save()
  }

  public remove(): void {
    Cookies.remove(this.COOKIE_NAME)
    this.state = this.getDefaultState()
  }

  public shouldShowBanner(): boolean {
    if (this.state.count >= this.MAX_DISMISSALS) {
      return false
    }

    return (
      !this.state.lastDismissed ||
      DateTime.now().diff(DateTime.fromISO(this.state.lastDismissed)) >=
        this.SHOW_INTERVAL
    )
  }

  public getState(): State {
    return { ...this.state }
  }
}

export default StateManager
