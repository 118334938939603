import { endpoints, http } from '@/api'
import { DATA_COLLECTION_TASK_BUILDER } from '@/constants'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_SSG_RESEARCHER_REGISTRATION_FLOW } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'
import { isNewAuthEnabled } from '@/utils/auth'
import Callback from '@/views/auth/Callback.vue'
import CallbackError from '@/views/auth/CallbackError.vue'

export default [
  {
    path: '/oauth/callback', // Needs to match redirect_uri in oidcSettings
    name: 'oidcCallback',
    component: Callback,
    meta: { isPublic: true, isCallback: true },
  },
  {
    path: '/oauth/callback-error',
    name: 'oidcCallbackError',
    component: CallbackError,
    meta: {
      isPublic: true,
      isCallback: true,
    },
  },
  {
    path: '/',
    component: () => import('@/components/layout/Guest.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/auth/Auth.vue'),
        children: [
          {
            path: 'register',
            name: 'auth.register',
            component: () => import('@/views/auth/UserSelector.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'signup-email',
            name: 'account.recovery',
            component: () =>
              import('@/views/account/recovery/AccountRecovery.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'register/researcher',
            name: 'auth.register.researcher',
            redirect: { name: 'auth.register.researcher.email' },
            meta: { isPublic: true },
          },
          {
            path: 'register/researcher/email',
            name: 'auth.register.researcher.email',
            beforeEnter: async (to, from, next) => {
              await Promise.all([
                store.dispatch('global/initLaunchDarkly'),
                store.dispatch('auth/fetchCountries'),
              ])

              /**
               * If we've attached a ?next= search parameter, then store this as the active route
               * and vuex oidc will automatically redirect to it after authentication
               */
              if (to.query.next) {
                sessionStorage.setItem('vuex_oidc_active_route', to.query.next)
              }

              if (featureIsEnabled(FLAG_SSG_RESEARCHER_REGISTRATION_FLOW)) {
                return next({
                  ...to,
                  name: 'auth.register.researcher.multi-step',
                  // this is necessary for back/forward motions to work properly!!
                  replace: true,
                })
              }

              next()
            },
            component: () =>
              import('@/views/auth/researcher/register/Email.vue'),
            meta: {
              isPublic: true,
            },
          },
          {
            path: 'register/participant',
            name: 'auth.register.participant',
            component: () =>
              import('@/views/auth/participant/waitlist/Waitlist.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'researcher/onboarding',
            name: 'auth.researcher.onboarding',
            component: () =>
              import('@/views/auth/researcher/ResearcherOnboarding.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/register/researcher/email',
    name: 'auth.register.researcher.multi-step',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('global/initLaunchDarkly')

      if (!featureIsEnabled(FLAG_SSG_RESEARCHER_REGISTRATION_FLOW)) {
        return next({
          ...to,
          name: 'auth.register.researcher.email',
          replace: true,
        })
      }

      next()
    },
    component: () => import('@/views/auth/researcher/register/MultiStep.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '',
    component: () => import('@/components/layout/Guest.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/auth/common/CenteredLayout.vue'),
        children: [
          {
            path: 'participant/onboarding',
            name: 'auth.participant.onboarding',
            component: () =>
              import('@/views/auth/participant/onboarding/Onboarding.vue'),
            meta: {
              isPublic: true,
              forceAuth: true,
            },
          },
          {
            path: 'participant/onboarding/register',
            name: 'auth.participant.onboarding.register',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/Register/Register.vue'
              ),
            meta: {
              isPublic: true,
              forceAuth: true,
              isOnboardingStep: true,
            },
            props: true,
          },
          {
            path: 'participant/onboarding/id-verification',
            name: 'auth.participant.onboarding.id_verification',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/IdVerification/IdVerification.vue'
              ),
            meta: {
              isOnboardingStep: true,
            },
          },
          {
            path: 'participant/onboarding/complete',
            name: 'auth.participant.onboarding.complete',
            component: () =>
              import('@/views/auth/participant/onboarding/Complete.vue'),
          },
          {
            path: 'participant/onboarding/error',
            name: 'auth.participant.onboarding.error',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/VerificationError.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: () => import('@/components/layout/Guest.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/auth/common/CenteredLayout.vue'),
        children: [
          {
            path: 'forgot-password',
            name: 'auth.passwordReset.email_form',
            beforeEnter: (to, from, next) =>
              isNewAuthEnabled()
                ? (window.location.replace(
                    import.meta.env['VUE_APP_CLIENT_PUBLIC_PATH']
                  ),
                  next(false))
                : next(),
            component: () => import('@/views/auth/passwordReset/EmailForm.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'forgot-password/email-sent',
            name: 'auth.passwordReset.email_sent',
            component: () => import('@/views/auth/passwordReset/EmailSent.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'forgot-password/invalid-link',
            name: 'auth.passwordReset.invalid_link',
            component: () =>
              import('@/views/auth/passwordReset/InvalidLink.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'forgot-password/new-password',
            name: 'auth.passwordReset.new_password',
            component: () =>
              import('@/views/auth/passwordReset/NewPassword.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'forgot-password/password-changed',
            name: 'auth.passwordReset.password_changed',
            component: () =>
              import('@/views/auth/passwordReset/PasswordChanged.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'register/participant/waitlist',
            name: 'auth.register.participant.waitlist',
            props: true,
            async beforeEnter(to, from, next) {
              const { campaign_code } = to.query
              if (campaign_code) {
                try {
                  const { prescreeners } = await http.get(
                    endpoints.URL_CAMPAIGN(campaign_code)
                  )

                  to.params.prescreeners = prescreeners
                  to.params.isValidCampaign = true
                } catch (e) {
                  // Request can fail because campaign is no longer active or doesnt exist
                  to.params.isValidCampaign = false
                } finally {
                  next()
                }
              } else {
                next()
              }
            },
            component: () =>
              import('@/views/auth/participant/waitlist/WaitlistSignUp.vue'),
            meta: { isPublic: true },
          },
          {
            path: 'register/participant/waitlist/verify-email',
            name: 'auth.register.participant.waitlist.verify_email',
            component: () =>
              import(
                '@/views/auth/participant/waitlist/WaitlistVerifyEmail.vue'
              ),
            meta: { isPublic: true },
          },
        ],
      },
      //feature-under-development route needs to be passed a direct home route
      {
        path: '',
        name: 'home',
        redirect: { path: '/' },
      },
      {
        path: '/feature-under-development',
        name: 'feature-under-development',
        component: import('@/views/FeatureUnderDevelopment.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'register/participant/referral',
        name: 'auth.register.participant.referral',
        props: true,
        component: () =>
          import('@/views/auth/participant/referees/RefereePage.vue'),
        async beforeEnter(to, from, next) {
          const { campaign_code } = to.query
          // If there is no campaign code, redirect to waitlist form
          if (!campaign_code) {
            next({ name: 'auth.register.participant.waitlist' })
          }
          // If there is a campaign code, check if it is valid (active and exists)
          else {
            try {
              // If the endpoint returns a 404, the campaign is invalid, or can no longer take referrals
              await http.get(endpoints.URL_CAMPAIGN(campaign_code))

              to.params.isValidReferralCampaign = true
            } catch (e) {
              to.params.isValidReferralCampaign = false
            } finally {
              // Always call next() to continue to the route, even if the campaign is invalid
              next()
            }
          }
        },
        meta: {
          isPublic: true,
        },
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'participant/onboarding/onboarding-study',
        name: 'auth.participant.onboarding.onboarding_study',
        component: () =>
          import('@/views/auth/participant/onboarding/OnboardingStudy.vue'),
        meta: {
          isOnboardingStep: true,
        },
      },
      {
        path: 'onboarding-study/start',
        name: 'auth.participant.onboarding.onboarding_study.start',
        beforeEnter(to, from, next) {
          if (store.getters['auth/user'].active_study_id !== 'onboarding') {
            next({ name: 'home' })
          } else {
            next()
          }
        },
        component: () =>
          import(
            '@/views/auth/participant/onboarding/InHouseOnboardingStudy.vue'
          ),
      },
      {
        path: 'data-collection-tool/campaign/:campaignId/task/:taskId',
        name: 'dc_tool',
        beforeEnter: async (to, from, next) => {
          const activeStudyId = store.getters['auth/activeStudyId']

          if (!activeStudyId) {
            return next({ name: 'error.404' })
          }

          const study = await store.dispatch(
            'participant/studies/fetchSingle',
            activeStudyId
          )

          const isDcToolStudy =
            study.data_collection_method === DATA_COLLECTION_TASK_BUILDER

          const isCampaignIdMatched =
            study.data_collection_id === to.params.campaignId

          if (!isDcToolStudy || !isCampaignIdMatched) {
            return next({ name: 'error.404' })
          }

          await store.dispatch('participant/dataCollectionTool/fetchTask', {
            campaignId: to.params.campaignId,
            taskId: to.params.taskId,
          })

          return next()
        },
        component: () => import('@/views/participant/DataCollectionTool.vue'),
        props: ({ params }) => ({
          campaignId: params.campaignId,
          taskId: params.taskId,
        }),
      },
    ],
  },
]
