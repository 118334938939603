<template>
  <DSDialogPanel v-bind="panelProps" :title="t('title')" class="panel">
    <p class="panel__description">{{ t('description') }}</p>
    <h3 class="panel__subtitle">{{ t('subtitle') }}</h3>
    <ol class="panel__steps">
      <li v-for="(step, index) in steps" :key="index" class="panel__step">
        {{ step }}
      </li>
    </ol>
    <Actions class="panel__actions">
      <DSButton
        variant="secondary"
        data-testid="cancel"
        @click="$emit('close')"
      >
        {{ t('buttons.cancel') }}
      </DSButton>
      <DSButton variant="primary" @click="$emit('enable')">
        {{ t('buttons.enable') }}
      </DSButton>
    </Actions>
  </DSDialogPanel>
</template>

<script setup lang="ts">
import { DSDialogPanel, DSButton } from '@prolific-oss/design-system'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Actions from '@/components/form/Actions.vue'

interface PanelProps {
  open: boolean
  onClose: () => void
}

defineProps<{
  panelProps: PanelProps
}>()

defineEmits<{
  (e: 'close'): void
  (e: 'enable'): void
}>()

const { t } = useI18n()

const steps = computed(() =>
  Array.from({ length: 3 }, (_, i) => t(`steps.${i}`))
)
</script>

<style lang="scss" scoped>
.panel {
  border-radius: var(--space-2);
  box-shadow: var(--box-shadow-light);

  @media screen and (width >= $md) {
    width: 75% !important;
  }
  @media screen and (width >= $lg) {
    width: 40% !important;
  }

  &__description {
    margin-bottom: var(--space-4);
  }

  &__subtitle {
    font-size: var(--space-4);
    font-weight: var(--font-weight-600);
    margin-bottom: var(--space-2);
  }

  &__steps {
    padding-left: var(--space-8);
    margin-bottom: var(--space-0);
  }

  &__step {
    margin-bottom: var(--space-1);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-4);
    border-bottom-left-radius: var(--space-2);
    border-bottom-right-radius: var(--space-2);

    :deep(.ds-button) {
      &:not(:last-child) {
        margin-right: var(--space-3);
      }
    }
  }
}
</style>

<i18n lang="yaml">
en:
  title: 'Protect your account'
  description: 'Further secure your Prolific account with multi-factor authentication (MFA). This extra layer of security combines your email and password with an additional verification method, reducing the risk of unauthorized access.'
  subtitle: 'How to get started:'
  steps:
    - 'Install a trusted authenticator app (e.g., Google Authenticator or Duo Mobile) on your smartphone.'
    - 'Click the "Enable MFA" button below.'
    - 'Use your authenticator app to scan the QR code, then enter the generated code to complete setup.'
  buttons:
    cancel: 'Cancel'
    enable: 'Enable MFA'
</i18n>
