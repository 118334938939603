import isEmpty from 'lodash/isEmpty'
import merge from 'ts-deepmerge'
import { endpoints, http } from '@/api'
import { getAccessDetailsHttpClient } from '@/api/accessDetails'
import { CredentialsClient } from '@/api/credentials'
import {
  isActive,
  isApproved,
  isAwaitingReview,
  isRejected,
  isReserved,
  isReturned,
  isScreenedOut,
  isTimedOut,
} from '@/api/statuses'
import {
  DEFAULT_LIST_PAGE_SIZE,
  DEFAULT_SUBMISSION_SORTING,
  DEFAULT_SUBMISSION_STATUS,
} from '@/constants'
import pusher from '@/integrations/pusher'
import rollbar from '@/integrations/rollbar'
import router from '@/router'
import download from '@/utils/download'
import { isAccessDetailCollectionUrl } from '@/utils/isAccessDetailCollectionUrl'
import notifier from '@/utils/notifier'
import common, { types as commonTypes } from '../../common'

export const types = {
  SET_TOTAL: 'SET_TOTAL',
  SET_TOTAL_APPROVED: 'SET_TOTAL_APPROVED',
  SET_TOTAL_EARNED_BY_CURRENCY: 'SET_TOTAL_EARNED_BY_CURRENCY',
  HAS_COMPLETED_SUBMISSION: 'HAS_COMPLETED_SUBMISSION',
  SET_CREDENTIALS: 'SET_CREDENTIALS',
  SET_ACCESS_DETAIL: 'SET_ACCESS_DETAIL',
  SET_PEC_ERROR_MODAL: 'SET_PEC_ERROR_MODAL',
}

const pusherEvents = {
  UPDATE: 'UPDATE',
}

const state = {
  total: 0,
  totalApproved: 0,
  totalEarnedByCurrency: [],
  submissions: {},
  submission: {},
  submissionsList: [],
  hasCompletedSubmission: false,
  credentials: {},
  allocatedAccessDetail: undefined,
  // Error modal for PEC-SUB-0002 and PEC-AB-0004
  showPECErrorModal: false,
}

const mutations = {
  [types.SET_TOTAL](state, total) {
    state.total = total
  },

  [types.SET_TOTAL_APPROVED](state, totalApproved) {
    state.totalApproved = totalApproved
  },

  [types.SET_TOTAL_EARNED_BY_CURRENCY](state, totalEarnedByCurrency) {
    state.totalEarnedByCurrency = totalEarnedByCurrency
  },

  [types.HAS_COMPLETED_SUBMISSION](state) {
    state.hasCompletedSubmission = true
  },

  [types.SET_CREDENTIALS](state, credentials) {
    state.credentials = credentials
  },

  [types.SET_ACCESS_DETAIL](state, externalUrl) {
    state.allocatedAccessDetail = externalUrl
  },

  [types.SET_PEC_ERROR_MODAL](state, showPECErrorModal) {
    state.showPECErrorModal = showPECErrorModal
  },
}

const actions = {
  setShowPECErrorModal({ commit }, showPECErrorModal) {
    commit(types.SET_PEC_ERROR_MODAL, showPECErrorModal)
  },

  subscribe({ commit, dispatch }, submission) {
    const { id } = submission
    const channel = pusher.subscribe(id)
    // unsubscribe/unbind first
    channel.unbind()
    channel.bind(pusherEvents.UPDATE, submission => {
      commit(commonTypes.UPDATE, { id, ...submission })

      if (!isActive(submission.status) && !isReserved(submission.status)) {
        dispatch('clearActiveStudy')
      }
    })
  },

  unsubscribe({ commit }, submission) {
    pusher.unsubscribe(submission.id)
  },

  async fetchSingle({ commit, rootGetters }, id) {
    const participantId = rootGetters['auth/currentUser'].id
    /**
     * 2 separate promises so that if the submission isn't found, we don't
     * bother requesting meta data as a 404 page will be rendered.
     */
    const submission = await http.get(endpoints.SUBMISSION(id))
    const meta = await http
      .get(endpoints.SUBMISSIONS, {
        params: {
          participant: participantId,
          page: 1,
          /**
           * We don't actually need any submissions back, just the meta data
           * so we can render the summary bar, however passing "0" as page_size
           * is interpreted as "20"
           */
          page_size: 1,
        },
      })
      .then(data => data.meta)

    const { count, total_approved, total_earned_by_currency } = meta

    const total_screened_out = meta.total_screened_out ?? 0

    commit(types.SET_TOTAL, count)
    commit(types.SET_TOTAL_APPROVED, total_approved + total_screened_out)
    commit(types.SET_TOTAL_EARNED_BY_CURRENCY, total_earned_by_currency)
    commit(commonTypes.SET_LIST, [submission])
    commit(commonTypes.SET_SINGLE, submission)
  },

  fetchList(
    { commit, rootGetters },
    {
      page = 1,
      ordering = DEFAULT_SUBMISSION_SORTING,
      status = DEFAULT_SUBMISSION_STATUS,
    }
  ) {
    // unset any existing state for a single study
    const participantId = rootGetters['auth/currentUser'].id

    // If status is provided, map it to the query params expected by the API: { awaiting_review: 1 }
    // If the status is the default, don't include it in the query params
    const statusFilter =
      status !== DEFAULT_SUBMISSION_STATUS ? { [status]: 1 } : {}

    return http
      .get(endpoints.SUBMISSIONS, {
        params: {
          participant: participantId,
          page,
          ordering,
          ...statusFilter,
          page_size: DEFAULT_LIST_PAGE_SIZE,
        },
      })
      .then(data => {
        const { results, meta } = data
        const { count, total_approved, total_earned_by_currency } = meta
        const total_screened_out = meta.total_screened_out ?? 0
        commit(types.SET_TOTAL, count)
        commit(types.SET_TOTAL_APPROVED, total_approved + total_screened_out)
        commit(types.SET_TOTAL_EARNED_BY_CURRENCY, total_earned_by_currency)
        commit(commonTypes.SET_LIST, results)
      })
  },

  async fetchAccessDetail(
    { commit, rootGetters },
    { collectionUrl, participantId, submissionId }
  ) {
    const token = rootGetters['oidc/oidcAccessToken']
    const accessDetailsHttpClient = getAccessDetailsHttpClient(token)

    try {
      const { externalUrl } = await accessDetailsHttpClient.patch(
        collectionUrl,
        {
          participantId,
          submissionId,
        },
        {
          headers: {
            'x-api-key': import.meta.env[
              'VUE_APP_TASK_ACCESS_DETAILS_SERVICE_API_KEY'
            ],
          },
        }
      )
      commit(types.SET_ACCESS_DETAIL, externalUrl)
    } catch (err) {
      rollbar.error('Error fetching access detail:', err)
      throw err
    }
  },

  export({ dispatch }) {
    dispatch('global/setLoading', true, { root: true })

    let ongoingWarning
    const timeoutId = setTimeout(() => {
      ongoingWarning = notifier.notification({
        showClose: false,
        duration: 0,
        type: 'warning',
        title:
          'Downloading large amounts of submissions can take some time! Please wait...',
        customClass: 'no-body',
      })
    }, 10000) // 10 seconds

    return http
      .get(endpoints.EXPORT_PARTICIPANT_SUBMISSIONS, { timeout: 0 })
      .then(data => {
        clearTimeout(timeoutId)
        if (ongoingWarning) ongoingWarning.close()
        download(data, 'my_prolific_submission_history.csv', 'text/csv')
        dispatch('global/setLoading', false, { root: true })
      })
  },

  update({ commit }, { id, ...patch }) {
    return http.patch(endpoints.SUBMISSION(id), patch).then(() => {
      commit(commonTypes.UPDATE, { id, ...patch })
    })
  },

  updateState({ commit }, { id, ...patch }) {
    commit(commonTypes.UPDATE, { id, ...patch })
  },

  reserve({ commit, dispatch, rootGetters }, { studyId, timeZone }) {
    const user = rootGetters['auth/currentUser']
    const participantId = user.id
    return http
      .post(
        endpoints.SUBMISSIONS_RESERVE,
        {
          studyId,
          participantId,
          ...(timeZone ? { timeZone } : {}),
        },
        {
          headers: {
            'x-prolific-id': participantId,
          },
          useGlobal429ErrorHandling: false,
        }
      )
      .then(submission => {
        commit('auth/UPDATE_USER', { active_study_id: studyId }, { root: true })

        return submission
      })
      .catch(async error => {
        if (error?.response?.status === 429) {
          const studiesPath = router.resolve({ name: 'studies' }).href
          await router.push({
            name: 'error.participant-studies-rate-limited',
          })

          // keep url as '/studies' rather than '/429' so that
          // the user can refresh the page and try again
          history.replaceState(null, '', studiesPath)
        }
        throw error
      })
  },

  unreserve({ commit, dispatch, getters }) {
    const { id } = getters.submission
    return http
      .post(endpoints.SUBMISSION_ACTION(id), { action: 'RETURN' })
      .then(submission => {
        commit(commonTypes.REPLACE, submission)
        dispatch('clearActiveStudy')
      })
  },

  startImmediately(
    { commit, rootGetters, dispatch, rootState },
    { studyId, hasAcceptedStudyTCs, timeZone } = {}
  ) {
    const user = rootGetters['auth/currentUser']
    const study = rootState.participant.studies.study
    const participantId = user.id
    // For tracking purposes only, send the ?source=pa|email
    // query param to the backend if present
    const source = router.currentRoute.value?.query?.source
    return http
      .post(
        endpoints.SUBMISSIONS,
        {
          studyId,
          participantId,
          terms_and_conditions_accepted: hasAcceptedStudyTCs,
          timeZone,
        },
        source ? { params: { source } } : {},
        { headers: { 'x-prolific-id': participantId } }
      )
      .then(submission => {
        const isOngoingStudy = study.is_ongoing_study
        if (isOngoingStudy) {
          commit('auth/ADD_ACTIVE_ONGOING_STUDY', study.id, {
            root: true,
          })
        } else {
          commit(
            'auth/UPDATE_USER',
            { active_study_id: studyId },
            { root: true }
          )
        }
        commit('SET_SINGLE', submission)
        dispatch('subscribe', submission)

        if (isAccessDetailCollectionUrl(submission.study_url)) {
          dispatch('fetchAccessDetail', {
            collectionUrl: submission.study_url,
            submissionId: submission.id,
            participantId: submission.participant_id,
          })
        }
        return submission
      })
  },

  start(
    { commit, getters, dispatch, rootState },
    { hasAcceptedStudyTCs } = {}
  ) {
    const { id } = getters.submission
    const study = rootState.participant.studies.study

    return http
      .post(endpoints.SUBMISSION_ACTION(id), {
        action: 'START',
        terms_and_conditions_accepted: hasAcceptedStudyTCs,
      })
      .then(submission => {
        const isOngoingStudy = study.is_ongoing_study

        if (isOngoingStudy) {
          dispatch('clearActiveStudy')
          commit('auth/ADD_ACTIVE_ONGOING_STUDY', study.id, {
            root: true,
          })
        }
        commit(commonTypes.REPLACE, submission)
        dispatch('subscribe', submission)
        if (isAccessDetailCollectionUrl(submission.study_url)) {
          dispatch('fetchAccessDetail', {
            collectionUrl: submission.study_url,
            submissionId: submission.id,
            participantId: submission.participant,
          })
        }
        return submission
      })
  },

  async startOnboardingStudy(
    { commit, dispatch, rootGetters, getters },
    studyId
  ) {
    await dispatch('startImmediately', { studyId })
    const { id: userId, onboarding } = rootGetters['auth/currentUser']
    // Get user channel already subscribed to from subscribeUser
    const channel = pusher.channel(userId)
    channel.bind(pusherEvents.UPDATE, ({ onboarding: newOnboarding }) => {
      if (
        newOnboarding?.onboarding_study?.completed &&
        onboarding.onboarding_study.completed !==
          newOnboarding?.onboarding_study?.completed
      ) {
        dispatch('clearActiveStudy')
        router.push({ name: 'auth.participant.onboarding' })
      }
    })
  },

  stop({ dispatch, commit, getters }, id) {
    id = id || getters.submission.id
    return http
      .post(endpoints.SUBMISSION_ACTION(id), { action: 'RETURN' })
      .then(submission => {
        dispatch('clearActiveStudy')
        dispatch('clearActiveOngoingStudy', submission.study.id)
        commit(commonTypes.REPLACE, submission)
      })
  },

  complete({ dispatch, commit, getters }, completionCode) {
    const { id } = getters.submission

    const payload = {
      action: 'COMPLETE',
    }

    if (completionCode) {
      payload.completion_code = completionCode
    }

    return http
      .post(endpoints.SUBMISSION_ACTION(id), payload)
      .then(submission => {
        dispatch('clearActiveStudy')
        dispatch('clearActiveOngoingStudy', submission.study.id)
        commit(commonTypes.REPLACE, submission)
      })
  },

  completeWithCode({ dispatch, commit }, { completionCode }) {
    return http
      .post(endpoints.SUBMISSION_COMPLETE, {
        completion_code: completionCode,
      })
      .then(submission => {
        dispatch('clearActiveStudy')
        dispatch('clearActiveOngoingStudy', submission.study.id)
        commit(commonTypes.REPLACE, submission)
        return submission
      })
  },

  completeOnboardingStudy({ dispatch, commit }, { studyResponse }) {
    return http.post(endpoints.SUBMIT_STUDY_ONBOARDING_STUDY, studyResponse)
  },

  rate(_, { study_id, rating }) {
    return http.post(endpoints.STUDY_RATING, {
      study_id,
      rating,
    })
  },

  clearActiveStudy({ commit }) {
    commit('auth/UPDATE_USER', { active_study_id: null }, { root: true })

    commit('global/SET_MESSAGE', null, { root: true })
    commit(types.HAS_COMPLETED_SUBMISSION)
  },

  clearActiveOngoingStudy({ commit }, id) {
    commit('auth/REMOVE_ACTIVE_ONGOING_STUDY', id, { root: true })
  },

  async fetchCredentials(
    { commit, rootGetters },
    { credentialPoolId, submissionId, studyId }
  ) {
    if (!submissionId) {
      return
    }

    const token = rootGetters['oidc/oidcAccessToken']
    const client = new CredentialsClient(token)

    try {
      const { username, password } = await client.redeemCredentialFromPool(
        credentialPoolId,
        submissionId
      )
      commit(types.SET_CREDENTIALS, { username, password })
    } catch (error) {
      rollbar.error('Error fetching credentials:', error)
      throw error
    }
  },
}

const getters = {
  total: ({ total }) => total,
  totalApproved: ({ totalApproved }) => totalApproved,
  totalEarnedByCurrency: ({ totalEarnedByCurrency }) => totalEarnedByCurrency,
  submission: ({ submission }) => submission,
  hasNoSubmission: ({ submission }) => !submission.id,
  isReserved: ({ submission }) => isReserved(submission),
  isActive: ({ submission }) => isActive(submission),
  isReturned: ({ submission }) => isReturned(submission),
  isRejected: ({ submission }) => isRejected(submission),
  isTimedOut: ({ submission }) => isTimedOut(submission),
  isAwaitingReview: ({ submission }) => isAwaitingReview(submission),
  isApproved: ({ submission }) => isApproved(submission),
  isScreenedOut: ({ submission }) => isScreenedOut(submission),
  isDone: (_state, getters) => !(getters.isReserved || getters.isActive),
  hasCode: ({ submission }) =>
    submission && submission.study_code && !isEmpty(submission.study_code),
  hasCompletedSubmission: state => state.hasCompletedSubmission,
  credentials: state => state.credentials,
  allocatedAccessDetail: state => state.allocatedAccessDetail,
  showPECErrorModal: state => state.showPECErrorModal,
}

export default merge(
  common({ entities: 'submissions', entity: 'submission' }),
  {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  }
)
