import { endpoints, http } from '@/api'
import { translate } from '@/i18n'
import store from '@/store'
import notifier from '@/utils/notifier'

export default [
  { path: '/account', redirect: { name: 'account.general' } },
  {
    path: '/researcher/account',
    name: 'account',
    redirect: { name: 'account.general' },
    component: () => import('@/views/account/common/AccountLayout.vue'),
    children: [
      {
        path: 'general',
        name: 'account.general',
        beforeEnter: (to, from, next) => {
          store.dispatch('auth/fetchCountries').then(() => next())
        },
        component: () => import('@/views/researcher/account/General.vue'),
      },
      {
        path: 'preferences',
        name: 'account.preferences',
        component: () => import('@/views/account/common/Preferences.vue'),
      },
    ],
  },
  // TODO: figure out if still necessary
  {
    path: '/researcher/confirm-US-state',
    name: 'researcher.confirm_US_state',
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/needsToConfirmUSState']) {
        return next({ name: 'home' })
      }
      next()
    },
    component: () => import('@/views/researcher/ConfirmUSState.vue'),
  },
  {
    path: '/researcher/verify-email',
    name: 'auth.verify_email',
    beforeEnter: (to, from, next) =>
      store.dispatch('auth/verifyEmail', to.query.i).then(() =>
        next({
          name: 'get-started',
        })
      ),
  },
  {
    path: '/researcher/reactivation-coupon/',
    name: 'researcher.reactivation_coupon',
    async beforeEnter(to, from, next) {
      try {
        await http.post(endpoints.REACTIVATION_COUPON)
        notifier.success(
          translate('notifications.coupon_redeemed_description'),
          {
            title: translate('notifications.coupon_redeemed_title'),
            duration: 0,
          }
        )
      } finally {
        next({ name: 'workspaces.redirect.finance' })
      }
    },
  },
  {
    path: '/researcher/coupon-redemption',
    name: 'coupon-redemption',
    async beforeEnter(to, from, next) {
      const couponCode = to?.query?.['coupon']

      if (!couponCode) {
        return next({ name: 'get-started' })
      }

      const payload = { referral_code: couponCode }

      try {
        const user = await http.post(endpoints.ASSIGN_COUPON, payload)

        store.dispatch('auth/setUser', user)
      } finally {
        next({ name: 'get-started' })
      }
    },
  },
]
