import type { Study } from '@/api/types'
import { createSchema, trackStructEvent } from '@/integrations/snowplow'

// TODO: this type needs properly defining
type ParticipantDashboardStudy = Study & {
  average_completion_time: Required<Study>['average_completion_time']
  study_average_reward_per_hour: Required<Study>['study_average_reward_per_hour']
  places_taken: Required<Study>['places_taken']
}

const createStudyCardsContext = (
  studies: ParticipantDashboardStudy[] = [],
  currencyCode: string
) => {
  return studies.map((study, index) => {
    return {
      schema: createSchema('study_card', '1-0-6'),
      data: {
        study_id: study.id,
        study_name: study.name,
        is_fixed_price_study: study.is_fixed_price_study,
        is_ongoing_study: study.is_ongoing_study,
        maximum_allowed_time_minutes: study.maximum_allowed_time,
        reward: study.study_reward.amount / 100,
        reward_per_hour: study.study_average_reward_per_hour.amount / 100,
        // Possible bug? The property says estimated_completion, but the value is average_estimated
        estimated_completion_time: study.average_completion_time,
        available_places: study.total_available_places - study.places_taken,
        display_currency: currencyCode,
        page_position: index,
      },
    }
  })
}

export const trackStudyDashboardEvent = (
  action: string,
  label: string,
  {
    studies,
    currencyCode,
    automatic,
    property,
  }: {
    studies: ParticipantDashboardStudy[]
    currencyCode: string
    automatic?: boolean
    property?: string
  }
) => {
  trackStructEvent({
    category: 'participant-study-dashboard',
    action,
    label,
    context: createStudyCardsContext(studies, currencyCode),
    property: property ? property : automatic ? 'automatic' : 'manual',
  })
}

/**
 * TODO: AC 12/08/2024. This is awkward since here tracking relates to a single
 * study but the context is an array of studies in the schema. I spoke with AJ about
 * this and he said it's ok to pass a single study in array format for the time being
 * but this is something that should refactored in the future.
 */
export const trackStudyDetailsEvent = (
  action: string,
  label: string,
  {
    study,
    currencyCode,
  }: {
    study: ParticipantDashboardStudy
    currencyCode: string
    automatic?: boolean
    property?: string
  }
) => {
  trackStructEvent({
    category: 'participant-study-details-page',
    action,
    label,
    context: createStudyCardsContext([study], currencyCode),
    property: study.id,
  })
}

export const trackStudyDashboardLoad = (
  numberOfStudies: number,
  automatic?: boolean
) => {
  trackStructEvent({
    category: 'participant-study-dashboard',
    action: 'study-dashboard-load',
    value: numberOfStudies,
    property: automatic ? 'automatic' : 'manual',
  })
}

export const trackStudyDashboardSort = (sortType: string) => {
  trackStructEvent({
    label: sortType,
    category: 'participant_study_sorting',
    action: 'activeSort',
  })
}

export const trackStudyDashboardContentTypeTogglePreference = (
  preference: string,
  action: 'enable' | 'disable'
) => {
  trackStructEvent({
    category: 'participant_study_preferences',
    label: 'content_type',
    property: preference,
    action,
  })
}
