import { computed } from 'vue'
import { useStore } from 'vuex'
import { trackAccountProtectionBannerEvent, Label } from '@/analytics/mfa'
import { StateManager } from '../utils'

export default () => {
  const store = useStore()
  const stateManager = new StateManager()
  const user = computed(() => store.getters['auth/user'])
  const trackEvent = (label: Label) => {
    const userType = user.value?.user_type
    const { count: totalDismissals } = stateManager.getState()
    trackAccountProtectionBannerEvent(label, { userType, totalDismissals })
  }

  return {
    trackEvent,
    Label,
  }
}
