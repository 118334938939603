import { createSchema } from '@/integrations/snowplow'

export enum Category {
  ACCOUNT_PROTECTION_BANNER = 'account_protection_banner',
}

export const CATEGORY_TO_SCHEMA_MAP = {
  [Category.ACCOUNT_PROTECTION_BANNER]: createSchema(
    Category.ACCOUNT_PROTECTION_BANNER,
    '1-0-0'
  ),
} as const

export enum Action {
  CLICK = 'click',
}

export const DEFAULT_ACTION = Action.CLICK

export enum Label {
  OPEN_MODAL = 'open_modal',
  CLOSE_BANNER = 'close_banner',
  CLOSE_MODAL = 'close_modal',
  ENABLE = 'enable',
}
