import { translate } from '@/i18n'
import store from '@/store'
import shouldRedirectActiveStudy from '@/utils/shouldRedirectActiveStudy'

export default function (to) {
  // does the participant have an active submission?
  const activeStudyId = store.getters['auth/activeStudyId']

  if (store.getters['auth/isAwaitingActivation']) {
    // display the account activation message
    store.dispatch(
      'global/setMessage',
      translate('notifications.is_awaiting_activation')
    )
  } else if (
    store.getters['auth/isParticipant'] &&
    !store.getters['auth/isCountryValid']
  ) {
    // display non oecd notification
    store.dispatch(
      'global/setMessage',
      translate('notifications.is_non_oecd_country')
    )
  } else if (!activeStudyId) {
    // clear active study notification
    store.dispatch('global/setMessage', null)
  }

  if (shouldRedirectActiveStudy(to.name, activeStudyId)) {
    // display active study notification
    store.dispatch('global/setLoading', false)
    store.dispatch(
      'global/setMessage',
      translate('notifications.active_submission')
    )
  }
}
