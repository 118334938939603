/** For all routes specific to researcher
 *  Access to these routes is restricted to a user role of researcher
 */
import store from '@/store'
import account from './account'
import integrations from './integrations'
import workspaces from './workspaces'

// Temporary redirects to account for researcher url restructure
const legacyRedirects = [
  'studies',
  'account',
  'topup',
  'submissions',
  'verify-email',
  'confirm-US-state',
].map(path => ({
  name: `legacy.${path}`,
  path: `/${path}/:pathMatch(.*)*`,
  redirect: to => {
    return {
      path: `/researcher/${path}/${to.params.pathMatch}`,
    }
  },
}))

export default [
  // Changing the home button to go to the get started page
  {
    path: '/',
    name: 'home',
    beforeEnter(to, from, next) {
      next({ name: 'get-started' })
    },
  },
  // TODO: Maybe this should sit somewhere else
  {
    meta: { nav: false },
    path: '/researcher/submissions/complete',
    name: 'researcher.submissions.complete',
    component: () => import('@/views/researcher/Complete.vue'),
  },

  // Child routes
  ...workspaces,
  ...integrations,
  ...account,

  {
    path: '/researcher/referrals',
    name: 'researcher.referrals',
    component: () => import('@/views/researcher/ReferralCampaign.vue'),
    async beforeEnter(to, from, next) {
      const user = store.getters['auth/user']
      if (!user.is_email_verified) {
        store.dispatch('auth/triggerVerificationDialogAction', true)
        return from.name ? next(from.name) : next({ name: 'get-started' })
      }

      try {
        await Promise.all([
          store.dispatch('auth/fetchResearcherReferralCampaign'),
          store.dispatch('auth/fetchResearcherReferrals', {
            includeClaimed: true,
          }),
        ])
      } finally {
        next()
      }
    },
  },

  ...legacyRedirects,

  {
    path: '/researcher/feature-under-development',
    name: 'feature-under-development',
    component: () => import('@/views/FeatureUnderDevelopment.vue'),
  },
  {
    path: '/404',
    name: 'error.404',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 404,
    }),
  },
  {
    path: '/500',
    name: 'error.500',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 500,
    }),
  },
  {
    path: '/429',
    name: 'error.429',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 429,
    }),
  },
  {
    path: '/researcher/api-only-feature',
    name: 'researcher.api-only-feature',
    component: () => import('@/views/APIOnly.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'error.404',
      // otherwise, `pathMatch` param will be forwarded and then error since it is not defined on 404 route
      params: {},
    },
  },
]
